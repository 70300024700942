@font-face {
  font-family: 'Gotham-Bold';
  src: url('./fonts/gotham_bold.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham-Norm';
  src: url('./fonts/gotham_medium.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


html{
  background-size: cover;
  background: linear-gradient(180deg, #F9F7F8 0%, #E9E7E7 100%) repeat-y;
  /*background: rgba(124, 43, 135, 1) linear-gradient(180deg, #7C2B87 0%, #452566 100%) ;*/
  min-width: 100vw;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  font-family: "Gotham-Norm", sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container{
  max-width: 1300px;
  box-sizing: border-box;
  padding-left: 16px;
  padding-right: 16px;
  margin: auto;
}

.footer-container{
  max-width: 1300px;
  box-sizing: border-box;
  display: flex;
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  margin: auto;
  flex-direction: column;
  align-items: center;
}

.footer-container-ar{
  max-width: 1300px;
  box-sizing: border-box;
  display: flex;
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  margin: auto;
  flex-direction: column;
  align-items: center;
}

.top-line{
  padding-top: 12px;
}

@media screen and (max-width: 600px) {
  .footer-container{
    align-items: flex-start;
  }
  .footer-container-ar{
    align-items: flex-end;
  }
  .footer-items{
    justify-content: start;
    align-items: flex-start;
  }
  .footer-items-ar{
    justify-content: start;
    align-items: flex-end;
  }
  .top-line{
    border-top: 1px solid rgba(27, 26, 25, 1);
    padding-top: 16px;
  }
}

table, th, td {
  border: 1px solid rgba(255, 255, 255, 0.45);
  border-collapse: collapse;
  padding: 4px;
}

table a{
  color: white;
}


.logo{
  width: 100%;
  height: 100%;
  max-width: 150px;
  max-height: 100px;
  min-width: 90px;
  min-height: 60px;
}

.langs{
  color: rgba(255, 255, 255, 0.60);
}

.langs a{
  cursor: pointer;
  padding: 4px 16px;
  transition: .2s color;
}

.langs a:hover{
  color: #FFF;
}

header{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.content{
  max-width: 1300px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.content h2{
  margin: 0;
  padding-top: 20px;
  max-width: 900px;
  color: #FFF;
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
}

.svg-width{
  width: 114px;
}

@media screen and (max-width: 600px) {
  .svg-width{
    width: 85px;
  }
}

.card{
  width: 180px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  transition: .2s opacity;
  text-transform: none;
  text-decoration: none;
}

.card:hover{
  opacity: 0.7;
}

.card img{
  width: 180px;
  height: 180px;
}

.wrapper{
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  gap: 24px;
  margin: auto;
  box-sizing: border-box;
  padding-left: 16px;
  padding-right: 16px;
}

.card div{
  display: flex;
  flex-direction: row;
  max-height: 29px;
  color: #FFF;
  align-items: center;
  gap: 4px;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 14px;
}

.button-block{
  display: flex;
  gap: 40px;
  margin-top: 40px;
  margin-bottom: 80px;
}

/*button{*/
/*  cursor: pointer;*/
/*  background: #7C2B87;*/
/*  color: #FFF;*/
/*  border: 1px solid #FFF;*/
/*  padding: 12px 24px;*/
/*  font-size: 16px;*/
/*  line-height: 24px;*/
/*  font-family: "Montserrat", sans-serif;*/
/*  font-weight: 600;*/
/*  border-radius: 50px;*/
/*  text-align: left;*/
/*  display: flex;*/
/*  align-items: center;*/
/*}*/

/*button p{*/
/*  margin-left: 10px;*/
/*  margin-top: 0;*/
/*  margin-bottom: 0;*/
/*}*/

.mobile-button-block{
  display: none;
}

.inst-header{
  display: none;
}

.inst-desktop{
  display: flex;
  position: absolute;
  top: -32px;
  right: -32px;
}

.swiper-button-block{
  display: none;
}

.swiper-slide-div{
  display: none;
}

.swiper-appear{
  display: none;
}

.swiper-appear-desktop{
  justify-content: center;
  align-items: flex-start;
  width: 880px;
  max-height: 736px;
  padding-top: 20px;
  padding-bottom: 20px;
  gap: 8px;
  display: flex;
  flex-wrap: wrap;
}

.swiper-appear-desktop{
  display: flex;
  flex-wrap: wrap;
}

.inst{
  position: relative;
  width: inherit;
  max-width: inherit;
  max-height: inherit;
  background: #6B1A69;
  padding: 0;
}

.padder-mobile{
  display: none;
}

.padder-desk{
  margin-top: 0;
  display: inline;
  background: #7c2b87;
  box-sizing: border-box;
  width: 100%;
  padding: 20px;
}

.mobile-marginer{
  margin-top: 0;
}

@media screen and (max-width: 600px) {
  .mobile-marginer{
    margin-top: 100px;
  }

  .padder-mobile{
    display: inline;
  }
  .padder-desk{
    display: none;
  }
  .footer-items-ar{
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .footer-items{
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .footer-items a{
    font-size: 12px;
    text-align: center;
    color: #1B1A19;
    text-decoration: none;
  }
  footer{
    box-sizing: border-box;
    width: 100vw;
    background: transparent;
    padding: 10px;
    display: flex;
    justify-content: center;
  }
  .logo {
    max-width: 90px;
    max-height: 60px;
  }
  .langs a{
    cursor: pointer;
    padding: 2px 8px;
    transition: .2s color;
  }

  .card{
    width: 175px;
  }

  .content h2{
    font-size: 20px;
    line-height: 32px;
    font-weight: 700;
  }
  .card img{
    width: 175px;
    height: 175px;
  }
  .button-block{
    display: none;
  }
  .wrapper{
    gap: 18px;
  }
  .mobile-button-block{
    z-index: 100;
    width: 100%;
    background: rgba(72, 38, 104, 1);
    bottom: 0;
    position: fixed;
    display: flex;
    justify-content: center;
    gap: 18px;
    padding-top: 10px;
    padding-bottom: 30px;
  }
  button{
    font-size: 14px;
    width: 180px;
  }
  .wrapper{
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    padding-left: 5px;
    padding-right: 5px;
  }
  .inst-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid white;
    padding-bottom: 12px;
  }
  .inst-desktop{
    display: none;
  }
  .swiper-button-block{
    display: flex;
    justify-content: space-between;
    gap: 16px;
    margin-top: 16px;
  }
  .swiper-appear{
    display: inline;
  }
  .swiper-appear-desktop{
    display: none;
  }
  .inst{
    position: relative;
    width: 100%;
    max-width: calc(100vw - 32px);
    height: 100%;
    background: #6B1A69;
    padding: 10px;
  }
}

@media screen and (max-width: 400px) {
  .card{
    width: 160px;
  }
  .card img{
    width: 160px;
    height: 160px;
  }
  button{
    font-size: 12px;
    width: 160px;
  }
  .wrapper{
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media screen and (max-width: 375px) {
  .inst img{
    max-width: 280px;
  }
}

@media screen and (max-width: 370px) {
  .card{
    width: 150px;
  }
  .card img{
    width: 150px;
    height: 150px;
  }
  button{
    font-size: 12px;
    width: 150px;
  }
}

@media screen and (max-height: 660px) {
  .inst img{
    max-width: 190px;
  }
}

.drop-show{
  display: flex!important;
}

.dropdown-control{
  position: relative!important;
  color:white!important;
  padding: 12px!important;
  padding-bottom: 4px!important;
  padding-top: 4px!important;
  width: 100%!important;
  border-radius: 100px!important;
  background-color: transparent!important;
  border: 1px solid #FFF!important;
  display: flex!important;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.Dropdown-arrow-wrapper{
  margin-top: 5px;
}

.dropdown-menu{
  height: 320px;
  margin-top: 10px!important;
  border-radius: 12px;
  background: white!important;
  border: 1px solid white!important;
}

.dropdown-arrow{
  background-color:white!important;
  fill: white!important;
}

.modal {
  position: fixed;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 100%;
  padding: 1px;
  background-color: rgba(0, 0, 0, 0.69);
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal-enter-active {
  animation: moveUp 0.7s ease-out forwards;
}

.modal-exit-active {
  animation: moveDown 0.7s ease-out forwards;
}

@keyframes moveUp {
  0% {
    transform: translate(-50%, 100%);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, -50%);
    opacity: 1;
  }
}

@keyframes moveDown {
  0% {
    transform: translate(-50%, -50%);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, 100%);
    opacity: 0;
  }
}

footer{
  box-sizing: border-box;
  width: 100vw;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  justify-content: center;
}

.footer{
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  width: 100vw;
}

.footer-items{
  padding-top: 10px;
  max-width: 800px;
  display: flex;
  align-items: flex-start;
  gap:24px;
  justify-content: space-between;
}

.footer-items-ar{
  padding-top: 10px;
  max-width: 800px;
  display: flex;
  align-items: flex-end;
  gap:24px;
  justify-content: space-between;
}

.footer-items a{
  font-size: 14px;
  text-align: center;
  color:#1B1A19;
  text-decoration: none;
}

.footer-items-ar a{
  font-size: 14px;
  text-align: center;
  color:white;
  text-decoration: none;
}

.padder{
  box-sizing: border-box;
}
